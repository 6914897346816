import React, { useState, useContext } from "react"
import PropTypes from "prop-types"
import { Container, Inner, Nav } from "./styles"
import Top from "./top"
import ProfileInfo from "./profile-info"
import Items from "./items"
import MobileApplicationList from "./mobile-application-list"
import LiveChat from "@localComponents/live-chat"
import { getBrand } from "@helpers/siteHelpers"
import { postRequest } from "@helpers/apiHelpers"
import UserContext from "@context/UserContext"
import { MenuProvider } from "@context/MenuContext"
import { withAlert } from "react-alert"

const Menu = (props) => {
  const { showMenu, toggleMenu, alert, currentPageInProfileChecklist, onboarding } = props
  const { user, menu } = useContext(UserContext)
  const { liveChat, showEmployers, userApplications } = menu
  const { access_token, current_user_masquerading, in_admin_view } = user

  const [showMobileApplicationList, setShowMobileApplicationList] = useState(false)

  const toggleMobileApplicationList = (event) => {
    if (event !== undefined) event.preventDefault()
    setShowMobileApplicationList(!showMobileApplicationList)
  }

  const logoutUser = (event) => {
    event.preventDefault()
    window.location.href = "/logout"
  }

  const toggleMenuAndMobileApplicationList = (event) => {
    event.preventDefault()
    toggleMobileApplicationList()
    toggleMenu()
  }

  const changeApplication = (event) => {
    event.preventDefault()

    const { id, brand } = event.currentTarget.dataset
    const brandObj = getBrand(brand)

    postRequest("/switch_applications/request_token", access_token, JSON.stringify({ application_container_id: id }))
      .then((data) =>
        window.open(
          `${brandObj.links.appBase[process.env.REACT_APP_ENV]}/switch-application/${
            data.switch_token
          }?&is_user_masquerading=${current_user_masquerading}`,
          "_self"
        )
      )
      .catch((error) => alert.error(`Could not switch application: ${error}`))
  }

  // no menu here to stop admins browsing via this, just return an empty fragment
  if (in_admin_view) return <></>

  return (
    <Container showMenu={showMenu}>
      <MenuProvider
        value={{
          showMenu,
          showMobileApplicationList,
          toggleMenu,
          toggleMobileApplicationList,
          toggleMenuAndMobileApplicationList,
          changeApplication,
          logoutUser
        }}
      >
        <Inner>
          <Nav>
            <Top />
            {!onboarding && (
              <>
                <ProfileInfo mobileOnly />
                <Items
                  currentPageInProfileChecklist={currentPageInProfileChecklist}
                  showCampOrResortButton={showEmployers}
                />
              </>
            )}
          </Nav>
          <ProfileInfo />
        </Inner>

        {userApplications.length > 1 && <MobileApplicationList />}

        <LiveChat {...liveChat} />
      </MenuProvider>
    </Container>
  )
}

Menu.propTypes = {
  showMenu: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired
}

export default withAlert()(Menu)
