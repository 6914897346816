export const googleTags = {
  GLOBAL: "GTM-KCF4KBPZ",
  ADVENTURE_ASIA: {
    ALL: "GTM-PM5R9Z9G"
  },
  ADVENTURE_CHINA: {
    ALL: "GTM-KQGG7MP4"
  },
  CAMP_CANADA: {
    AU: "GTM-NMHKKZJ",
    CA: "GTM-P423S5B",
    DE: "GTM-MFQCMFX",
    ES: "GTM-KPJSXG3",
    EU: "GTM-NCMDJKS",
    FR: "GTM-KVRB8NX",
    GB: "GTM-5BQBWD5",
    IE: "GTM-WKXFB2C",
    MX: "GTM-NHTK6L7",
    NZ: "GTM-PRD7ZH5",
    US: "GTM-M5SXG4S"
  },
  CAMP_LEADERS: {
    AU: "GTM-PTSV496",
    CA: "GTM-5N2T59R",
    CO: "GTM-58GCPC9",
    CZ: "GTM-5S8PF8G",
    DE: "GTM-PM24M6P",
    ES: "GTM-M855C35",
    GB: "GTM-W4D36PP",
    HU: "GTM-MSGK5S2",
    IE: "GTM-MQ7NDQ2",
    IL: "GTM-TNKF9WG",
    MX: "GTM-5F57MPW",
    NZ: "GTM-5LQ425W",
    PL: "GTM-NC84PDJ",
    ROEU: "GTM-K9N9CHD",
    ROSA: "GTM-MT9P4BH",
    SK: "GTM-KXNGSX2",
    US: "GTM-5M87TB8",
    WW: "GTM-TSCT7VS",
    ZA: "GTM-537VLPP"
  },
  CANAGO: {
    ALL: "GTM-MWB955FC"
  },
  RESORT_LEADERS: {
    CL: "GTM-5N5329M",
    CZ: "GTM-59X7NVS",
    DE: "GTM-5K5VR42",
    GB: "GTM-5Z4QC8V",
    HU: "GTM-WZRG55W",
    MX: "GTM-MVLX5MD",
    PE: "GTM-NMQ2N62Q",
    PL: "GTM-K57BTTZ",
    SK: "GTM-MVZN9HT",
    US: "GTM-PSLBRGW"
  },
  SMALLER_EARTH: {
    AU: "GTM-NCKR3X4",
    CZ: "GTM-TRLHGRW",
    DE: "GTM-T7P544W",
    GB: "GTM-5PWXWG8",
    HU: "GTM-P6J7T3C",
    IE: "GTM-TVCFRSC",
    MX: "GTM-WDH77J7",
    NZ: "GTM-WP22FVJ",
    PL: "GTM-PNCLPZJ",
    SK: "GTM-5VG24RR",
    US: "GTM-NH4XRBD"
  }
}

export function getGoogleTag(channel, locale) {
  if (!channel || !locale) return

  channel = channel.toUpperCase().replace(/ /g, "_")
  locale = locale.toUpperCase()

  if (!googleTags[channel]) return
  return googleTags[channel][locale] || googleTags[channel].ALL
}
