import React from "react"
import { UserConsumer } from "@context/UserContext"

// Also update in https://github.com/Cleversteam/Hanover/blob/main/app/models/concerns/checklistable.rb
const CHECKLIST_NO_LOCK = [
  "participant_agreements",
  "documents",
  "flight",
  "insurance",
  "events",
  "payments",
  "police_check",
  "work_permits",
  "visa",
  "interviews",
  "courses",
  "referrals",
  "linked_application"
]

const withChecklist = (itemName) => (WrappedComponent) => {
  return (props) => (
    <UserConsumer>
      {({ menu: { checklist } = { checklist: [] } }) => {
        const profileStatus = () => {
          try {
            const items = checklist.filter((item) => item.profile_position === "inside")
            if (
              items
                .filter((item) => !CHECKLIST_NO_LOCK.includes(item.system_name))
                .every((item) => item.status === "locked")
            )
              return "locked"
            else if (items.every((item) => item.status === "incomplete")) return "incomplete"
            else if (items.every((item) => item.status === "complete")) return "complete"
            else return "started"
          } catch {
            return "incomplete"
          }
        }

        return <WrappedComponent {...props} checklist={checklist} profileStatus={profileStatus()} />
      }}
    </UserConsumer>
  )
}

export default withChecklist
