import backgroundImage from "../assets/images/backgrounds/smallerearth.jpg"
import { tint } from "polished"

const black = "#000000"
const grey = "#333333"
const white = "#FFFFFF"
const yellow = "#FFEB57"
const green = "#87F9B5"
const blue = "#7AECFC"
const red = "red"
const lightGrey = "#F8F8F8"
const darkGrey = "#1F2B30"
const inputFieldGrey = "#F6F6F6"
const inputFieldBlack = "#515151"
const inputFieldBorder = "#CCCCCC"
const inputFieldHint = "#999999"

const smallerearth = {
  colors: {
    brand: black,
    brand2: white,
    body: white,
    background: white,
    primary: black,
    secondary: grey,
    primaryContrasted: tint(0.3, black),
    primaryContrastedDarker: tint(0.4, black),
    success: yellow,
    warning: blue,
    danger: red,
    lightGrey: lightGrey,
    darkGrey: darkGrey,
    rgbaBrandLightened: "rgb(0 0 0 / 10%)",
    notifications: {
      meta: white,
      badge: {
        background: red,
        text: black
      }
    },
    logoutButton: {
      main: yellow,
      text: black
    },
    statuses: {
      locked: red,
      incomplete: white,
      complete: green,
      started: yellow,
      rejected: red
    },
    home: {
      headings: black
    },
    content: {
      background: lightGrey,
      text: darkGrey,
      url: darkGrey
    },
    contactInfo: {
      background: lightGrey,
      title: black,
      text: black
    },
    applicationProgress: {
      value: black,
      bar: "#eeeeee",
      label: "#717171",
      stage: "#19434a"
    },
    tables: {
      headings: yellow
    },
    buttons: {
      color: black,
      lightColor: white,
      darkColor: black,
      special: {
        main: yellow,
        text: black
      }
    },
    incompletion: {
      lightenAmount: 0.1,
      background: blue
    },
    fields: {
      text: inputFieldBlack,
      contentText: inputFieldBlack,
      contentHeading: "#666666",
      label: "#333333",
      border: inputFieldBorder,
      hint: inputFieldHint,
      fieldsetText: "#717171",
      backButton: "#CCCCCC",
      backButtonText: "#666666",
      invalid: {
        darkenAmount: 0
      },
      backgroundColor: inputFieldGrey,
      spacer: "#F2F2F2"
    },
    alerts: {
      info: "#004152",
      danger: "#FF2230",
      success: "#8FCB24"
    },
    conversations: {
      activeChat: "rgba(128, 128, 128, 0.1)"
    }
  },
  fonts: {
    body: {
      family: "Helvetica Neue",
      weight: "400"
    },
    headings: {
      family: "Helvetica Neue",
      weight: "700"
    }
  },
  images: {
    background: backgroundImage
  }
}

export default smallerearth
