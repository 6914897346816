import backgroundImage from "../assets/images/backgrounds/americancampco.jpg"
import { tint } from "polished"

const navy = "#062031"
const offWhite = "#EDE8DF"
const red = "#F03D07"
const brightBlue = "#9AE7ED"
const yellow = "#FAB700"
const green = "#99CC00"
const lightGrey = "#F8F8F8"
const darkGrey = "#1F2B30"
const white = "#FFFFFF"
const lightNavy = "#034253"
const inputFieldGrey = "#F6F6F6"
const inputFieldBlack = "#515151"
const inputFieldBorder = "#CCCCCC"
const inputFieldHint = "#999999"

const americancampco = {
  colors: {
    brand: navy,
    brand2: lightNavy,
    body: white,
    background: white,
    primary: navy,
    secondary: lightNavy,
    primaryContrasted: tint(0.2, navy),
    primaryContrastedDarker: tint(0.3, navy),
    success: brightBlue,
    warning: yellow,
    danger: red,
    lightGrey: lightGrey,
    darkGrey: darkGrey,
    rgbaBrandLightened: "rgb(6 32 49 / 10%)",
    notifications: {
      meta: brightBlue,
      badge: {
        background: red,
        text: white
      }
    },
    logoutButton: {
      main: brightBlue,
      text: darkGrey
    },
    statuses: {
      locked: red,
      incomplete: darkGrey,
      complete: green,
      started: yellow,
      rejected: red
    },
    home: {
      headings: navy
    },
    content: {
      background: lightGrey,
      text: lightNavy,
      url: brightBlue
    },
    contactInfo: {
      background: lightGrey,
      title: navy,
      text: red
    },
    applicationProgress: {
      value: red,
      bar: "#eeeeee",
      label: "#717171",
      stage: "#19434a"
    },
    tables: {
      headings: offWhite
    },
    buttons: {
      color: darkGrey,
      lightColor: white,
      darkColor: darkGrey,
      special: {
        main: brightBlue,
        text: darkGrey
      }
    },
    incompletion: {
      lightenAmount: 0.3,
      background: yellow
    },
    fields: {
      text: inputFieldBlack,
      contentText: inputFieldBlack,
      contentHeading: "#666666",
      label: "#333333",
      border: inputFieldBorder,
      hint: inputFieldHint,
      fieldsetText: "#717171",
      backButton: "#CCCCCC",
      backButtonText: "#666666",
      invalid: {
        darkenAmount: 0
      },
      backgroundColor: inputFieldGrey,
      spacer: "#F2F2F2"
    },
    alerts: {
      info: "#004152",
      danger: "#FF2230",
      success: "#8FCB24"
    },
    conversations: {
      activeChat: "rgba(6, 31, 50, 0.1)"
    }
  },
  fonts: {
    body: {
      family: "Helvetica Neue",
      weight: "400"
    },
    headings: {
      family: "Helvetica Neue",
      weight: "700"
    }
  },
  images: {
    background: backgroundImage
  }
}

export default americancampco
