import React, { useContext, useState, useEffect } from "react"
import UserContext from "@context/UserContext"
import MenuContext from "@context/MenuContext"
import { Container, Inner, Text, Name, Programme, ApplicationId, StyledButton, OptionsPopupOuter } from "./styles"
import { NavIcons, Avatar, OptionsPopup } from "@cleversteam/se-hanover-components"
import { Link, withRouter } from "react-router-dom"

const ProfileInfo = (props) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [applicationSwitcherOpen, setApplicationSwitcherOpen] = useState(false)
  const applicationSwitcherClassName = "applications_list_box"
  const {
    avatar_exists,
    avatar_path,
    first_name,
    last_name,
    current_application_container_programme,
    current_application_container_id
  } = useContext(UserContext).user
  const { logoutUser } = useContext(MenuContext)
  const { checklist } = useContext(UserContext).menu

  const basicInfoIsLocked = checklist.find((item) => item.system_name === "basic_info")?.locked
  const profileInfoUrl = basicInfoIsLocked ? "" : "/basic-info/step-one"

  useEffect(() => {
    const handleWindowClick = (event) => {
      const outsideOptionsBox = !event.target.closest(".profile_info_options_box")
      if (outsideOptionsBox) setMenuOpen(false)
    }

    if (menuOpen) {
      window.addEventListener("click", handleWindowClick)
    } else {
      window.removeEventListener("click", handleWindowClick)
    }

    return () => window.removeEventListener("click", handleWindowClick)
  }, [menuOpen])

  useEffect(() => {
    const handleWindowClick = (event) => {
      const outsideOptionsBox = !event.target.closest(`.${applicationSwitcherClassName}`)
      if (outsideOptionsBox) setApplicationSwitcherOpen(false)
    }

    if (applicationSwitcherOpen) {
      window.addEventListener("click", handleWindowClick)
    } else {
      window.removeEventListener("click", handleWindowClick)
    }

    return () => window.removeEventListener("click", handleWindowClick)
  }, [applicationSwitcherOpen])

  const toggleMenu = (event) => {
    event.preventDefault()
    setMenuOpen(!menuOpen)
  }

  const linkStyle = {
    textDecoration: "none"
  }

  return (
    <>
      <Container mobileOnly={props.mobileOnly}>
        <Inner>
          <Link to={profileInfoUrl} style={linkStyle}>
            <Avatar
              avatarExists={avatar_exists}
              src={avatar_path}
              alt="Avatar"
              firstName={first_name}
              lastName={last_name}
              size="small"
              plain={true}
            />
          </Link>
          <Text>
            <Name>
              <Link to={profileInfoUrl} style={linkStyle}>
                {first_name}
              </Link>
              <ApplicationId>ID: {current_application_container_id}</ApplicationId>
            </Name>
            <Link to={profileInfoUrl} style={linkStyle}>
              <Programme>{current_application_container_programme}</Programme>
            </Link>
          </Text>
        </Inner>

        <StyledButton href="" onClick={toggleMenu} desktopOnly>
          <NavIcons.EllipsisIcon size="lg" color="#ffffff" />
        </StyledButton>
      </Container>

      {menuOpen && (
        <OptionsPopupOuter>
          <OptionsPopup.Box className="profile_info_options_box">
            <OptionsPopup.List>
              <OptionsPopup.ListItem>
                <OptionsPopup.Link onClick={logoutUser}>
                  <OptionsPopup.LinkText>
                    <NavIcons.LogoutIcon size="lg" color="#555555"></NavIcons.LogoutIcon>
                    <span>Log out</span>
                  </OptionsPopup.LinkText>
                </OptionsPopup.Link>
              </OptionsPopup.ListItem>
            </OptionsPopup.List>
          </OptionsPopup.Box>
        </OptionsPopupOuter>
      )}
    </>
  )
}

export default withRouter(ProfileInfo)
