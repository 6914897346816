import React, { useContext } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { Container, Buttons, Brand, HeaderBar, BrandOverlay, Video } from "./styles"
import Tagline from "@localComponents/tagline"
import OpenMenuButton from "@localComponents/open-menu-button"
import OpenMessagesButton from "@localComponents/open-messages-button"
import OpenNotificationsButton from "@localComponents/open-notifications-button"
import Logo from "@localComponents/logo"
import ApplicationProgress from "@localComponents/application-progress"
import UserContext from "@context/UserContext"

const getVideoMimeType = (url) => {
  const ext = url.substr(url.length - 4)
  if (ext === ".mp4") return "video/mp4"
  else if (ext === ".ogg") return "video/ogg"
  else if (ext === "webm") return "video/webm"

  return "video/mp4"
}

const Header = ({ homeContent, bannerVideoPath, bannerVideoPoster, toggleMenu, onboarding }) => {
  const { progressInfo, notificationCount, messagingEnabled, messageCount } = useContext(UserContext).menu

  const headerButtons = ({ isMobileLayout }) => (
    <Buttons isMobileLayout={isMobileLayout}>
      <OpenNotificationsButton notificationCount={notificationCount} />

      {!onboarding && (
        <>
          {messagingEnabled && <OpenMessagesButton messageCount={messageCount} />}
          {isMobileLayout && <OpenMenuButton toggleMenu={toggleMenu} />}
        </>
      )}
    </Buttons>
  )

  return (
    <>
      <HeaderBar>
        <ApplicationProgress
          progressPercentage={progressInfo.application_progress}
          currentStageRank={progressInfo.current_stage_rank}
          maxStageRank={progressInfo.max_stage_rank}
          stage={progressInfo.stage}
          inEndStage={progressInfo.in_end_stage}
          desktopOnly
          nomargin
        />
        {headerButtons({ isMobileLayout: false })}
      </HeaderBar>

      <Container isHome={!!homeContent} withVideo={homeContent && bannerVideoPath}>
        <Brand>
          <BrandOverlay>
            <Link to="/">
              <Logo mobileOnly medium />
            </Link>
            {headerButtons({ isMobileLayout: true })}
          </BrandOverlay>

          {bannerVideoPath && (
            <Video
              id="background-video"
              autoPlay
              loop
              muted
              playsInline
              disableRemotePlayback
              poster={bannerVideoPoster}
            >
              <source src={bannerVideoPath} type={getVideoMimeType(bannerVideoPath)} />
            </Video>
          )}

          {homeContent && <Tagline />}
        </Brand>
      </Container>
    </>
  )
}

Header.propTypes = {
  homeContent: PropTypes.object,
  toggleMenu: PropTypes.func.isRequired
}

export default Header
