import React from "react"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  appVersion: process.env.REACT_APP_VERSION,
  enabledReleaseStages: ["production", "staging"],
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_ENV
})

export const BugsnagBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React)
