import React, { Component } from "react"
import { Redirect } from "react-router-dom"
import Menu from "@localComponents/menu"
import Heading from "@localComponents/heading"
import Head from "@localComponents/head"
import Incompletion from "@localComponents/incompletion"
import Header from "@localComponents/header"
import { GlobalStyles, Main, Content, Body } from "./styles"
import UserContext from "@context/UserContext"
import { capitalize } from "@helpers/stringHelpers"
import { getEnvironment } from "@helpers/siteHelpers"
import { EnvironmentRibbon } from "@cleversteam/se-hanover-components"

class PrivateLayout extends Component {
  static contextType = UserContext

  constructor(props) {
    super(props)

    this.state = {
      showMenu: false,
      currentItem: null
    }
  }

  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu })
  }

  render() {
    const { title, inProfileChecklist, children, simpleFormLayout } = this.props
    const { showMenu, currentItem } = this.state
    const { toggleMenu } = this
    const { in_admin_view } = this.context.user
    const environment = getEnvironment()

    return currentItem && !!currentItem.locked && !in_admin_view ? (
      <Redirect to="/403" />
    ) : (
      <>
        <Head title={title} />
        <GlobalStyles simpleForm={simpleFormLayout} />
        {environment !== "production" && <EnvironmentRibbon environment={capitalize(environment)} />}
        <Body>
          <Menu toggleMenu={toggleMenu} showMenu={showMenu} currentPageInProfileChecklist={inProfileChecklist} />
          <Content>
            <Header toggleMenu={toggleMenu} />
            <Main simpleForm={simpleFormLayout}>
              {title && <Heading simple={simpleFormLayout} title={capitalize(title)} />}
              {currentItem && <Incompletion message={currentItem.incompletion_message} />}
              {children}
            </Main>
          </Content>
        </Body>
      </>
    )
  }
}

PrivateLayout.defaultProps = {
  itemName: "",
  inProfileChecklist: false
}

export default PrivateLayout
