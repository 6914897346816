import Pluralize from "pluralize"

export function parameterize(str = "", separator = "-") {
  return str
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 -]/, "")
    .replace(/\s/g, separator)
}

export function toBool(value = "") {
  return ["true", "yes", "1"].includes(value.toString().trim().toLowerCase())
}

export function urlWithProtocol(str) {
  if (/^((http|https):\/\/)/.test(str)) return str
  else return "https://" + str
}

export function capitalize(str) {
  if (typeof str !== "string") return ""

  return initialise(str) + str.slice(1)
}

export function initialise(str) {
  if (typeof str !== "string") return ""

  return str.charAt(0).toUpperCase()
}

export function camelize(str, capitalizeFirst = false) {
  str = str
    .replace(/_+/g, " ")
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, "")
  return capitalizeFirst ? capitalize(str) : str
}

export function pluralize(str) {
  return Pluralize(str)
}

export function truncateNearestWord(str, maxLength) {
  try {
    let output = ""
    let currentLength = 0
    let insideTag = false
    let insideMarkdownLink = false
    let tagBuffer = ""
    let markdownBuffer = ""

    for (let i = 0; i < str.length; i++) {
      const char = str[i]

      // Check if we're inside an HTML tag
      if (char === "<") {
        insideTag = true
        tagBuffer = "<"
        continue
      }

      if (insideTag) {
        tagBuffer += char
        if (char === ">") {
          insideTag = false
          output += tagBuffer
          tagBuffer = ""
        }
        continue
      }

      // Check if we're inside a Markdown link
      if (char === "[") {
        insideMarkdownLink = true
        markdownBuffer = "["
        continue
      }

      if (insideMarkdownLink) {
        markdownBuffer += char
        if (char === "]") {
          let linkPart = ""
          let j = i + 1
          if (str[j] === "(") {
            while (j < str.length && str[j] !== ")") {
              linkPart += str[j]
              j++
            }
            if (str[j] === ")") {
              markdownBuffer += linkPart + ")"
              output += markdownBuffer
              i = j
              insideMarkdownLink = false
              continue
            }
          }
        }
        continue
      }

      // Add characters to the output while under the limit
      if (currentLength < maxLength) {
        output += char
        currentLength++
      } else {
        // Check if we are in the middle of a link or tag
        if (insideTag || insideMarkdownLink) {
          continue // Skip truncating in the middle of a tag or link
        }
        break
      }
    }

    return output
  } catch (error) {
    console.error("truncateNearestWord error", error)

    let trimmedString = str.substr(0, maxLength)
    return trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))
  }
}

export function jsonToBase64(object) {
  const json = JSON.stringify(object)
  return btoa(json)
}

export function base64ToJson(base64String) {
  const json = atob(base64String)
  return JSON.parse(json)
}
