import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import BrandContext from "@context/BrandContext"
import UserContext from "@context/UserContext"
import { getEnvironment, getTheme } from "@helpers/siteHelpers"

const Head = (props) => {
  const brand = useContext(BrandContext)
  const { menu } = useContext(UserContext)

  const { title } = props
  const { name, friendlyName } = brand
  const { liveChat } = menu
  const theme = getTheme()
  const environment = getEnvironment()

  let pageTitle = title ? `${title} | ${friendlyName}` : friendlyName
  if (["development", "staging"].includes(environment)) pageTitle = `[${environment.toUpperCase()}] ${pageTitle}`

  return (
    <Helmet defer={false}>
      <title>{pageTitle}</title>
      <meta name="theme-color" content={theme.colors.brand} />
      <link rel="icon" href={`/assets/${name}/favicon.png`} />
      <link rel="manifest" href={`/manifests/${name}.json`} />
      <script src="https://js.stripe.com/v3" async></script>
      <script src="/src.js"></script>

      {environment === "production" &&
        liveChat &&
        liveChat.integration_type === "missive" &&
        liveChat.integration_key && (
          <script>{`
          (function(d, w) {
            w.MissiveChatConfig = {
              "id": "${liveChat.integration_key}",
              "chat": {
                "hidden": true
              },
              "user": ${JSON.stringify(liveChat.integration_user_attributes)}
            };

            var s = d.createElement('script');
            s.async = true;
            s.src = 'https://webchat.missiveapp.com/' + w.MissiveChatConfig.id + '/missive.js';
            if (d.head) d.head.appendChild(s);
          })(document, window);
        `}</script>
        )}
    </Helmet>
  )
}

export default Head
