import styled, { css } from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { NavLink } from "react-router-dom"

const itemStyles = css`
  display: block;
  text-decoration: none;
  color: ${(props) => props.theme.colors.body};
  font-weight: 500;
  width: 100%;
  padding: 0.5rem 0;
  cursor: pointer;
  user-select: none;
`

export const ItemList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

export const SubList = styled.ul`
  list-style: none;
  padding: 0.2rem 0;
  margin: 0.2rem 0 0.1rem 3.5em;
  display: none;

  ${(props) =>
    props.showSubList &&
    css`
      display: block;
    `}
`

export const StyledLink = styled(NavLink)`
  ${itemStyles}
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2rem;
  box-sizing: border-box;
  padding: 0.75rem 1rem;
  font-size: 20px;

  ${breakpoint("desktop")`
    font-size: 18px;
  `}

  ${breakpoint("widescreen")`
    font-size: 20px;
  `}

  ${({ info }) =>
    info &&
    breakpoint("desktop")`
    font-size: 14px;
    line-height: 150%;
    padding: 0.25rem 1.25rem;
  `}

  ${({ indent }) =>
    indent &&
    css`
      margin: 0rem 0 0.1rem 2.15rem;
    `}

  ${({ info }) =>
    info &&
    breakpoint("widescreen")`
    font-size: 16px;
  `}

  &.active {
    background: ${(props) => props.theme.colors.primaryContrasted};
    border-radius: 0.5rem;
  }
`

export const StyledExternalLink = styled.a`
  ${itemStyles}
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2rem;
  padding: 0;
  box-sizing: border-box;
  padding: 0.75rem 1rem;
  font-size: 20px;

  ${breakpoint("desktop")`
    font-size: 18px;
  `}

  ${breakpoint("widescreen")`
    font-size: 20px;
  `}

  ${({ info }) =>
    info &&
    breakpoint("desktop")`
      font-size: 14px;
      line-height: 150%;
      padding: 0.25rem 1.25rem;
  `}

  ${({ info }) =>
    info &&
    breakpoint("widescreen")`
    font-size: 16px;
  `}

  &.active {
    background: ${(props) => props.theme.colors.primaryContrasted};
    border-radius: 0.5rem;
  }
`

export const SubListTitle = styled.span`
  ${itemStyles}
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2rem;
  padding: 8px 0;
  box-sizing: border-box;
  padding: 0.75rem 1rem;
  font-size: 20px;

  ${breakpoint("desktop")`
    font-size: 18px;
  `}

  ${breakpoint("widescreen")`
    font-size: 20px;
  `}


  &.active {
    background: ${(props) => props.theme.colors.primaryContrasted};
    border-radius: 0.5rem;
  }
`
export const SubListTitleLeft = styled.span`
  display: flex;
  align-items: center;
`

export const SubListTitleRight = styled.span`
  display: flex;
  align-items: center;
  gap: 0.6rem;
`

export const Item = styled.li`
  display: block;
  width: 100%;

  ${(props) =>
    props.extraMargin &&
    css`
      margin-bottom: 0.5rem;
    `}

  ${(props) =>
    props.hidden &&
    css`
      display: none;
    `}

  ${(props) =>
    props.mobileOnly &&
    breakpoint("desktop")`
      display: none;
  `}

  ${(props) =>
    props.desktopOnly &&
    breakpoint("desktop")`
      display: block;
  `}
`

export const SubItemText = styled.span`
  color: ${(props) => props.theme.colors.body};
  font-size: 16px;

  ${breakpoint("desktop")`
    font-size: 14px;
  `}

  ${breakpoint("widescreen")`
    font-size: 16px;
  `}

  font-weight: 500;
`

export const SubLink = styled(NavLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;
  cursor: pointer;
  border-radius: 2rem;
  padding: 0.25rem 0;
  box-sizing: border-box;
  margin-bottom: 0.2rem;
  color: white;
  gap: 0.5em;
  font-size: 16px;

  ${breakpoint("desktop")`
    font-size: 14px;
  `}

  ${breakpoint("widescreen")`
    font-size: 16px;
  `}

  &.active {
    font-weight: 700;
  }
`

export const LockedSubLink = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: default;
  border-radius: 2rem;
  padding: 6px 0;
  box-sizing: border-box;
  margin-bottom: 0.2rem;
  text-align: center;
  gap: 0.45em;
`

export const LockedLink = styled.div`
  ${itemStyles}
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2rem;
  box-sizing: border-box;
  padding: 0.75rem 1rem;
  font-size: 20px;

  ${breakpoint("desktop")`
    font-size: 18px;
  `}

  ${breakpoint("widescreen")`
    font-size: 20px;
  `}
`

export const MenuIconOuter = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.6rem;

  .hanover-nav-icon {
    path {
      stroke: currentColor;
    }
  }
`
export const SpinnerOuter = styled.div`
  margin-top: 15vh;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`

export const ItemRight = styled.div`
  display: flex;
  gap: 0.6rem;
`

export const ItemRightOuter = styled.div`
  text-align: right;
  width: 1.1em;
  overflow: hidden;
  margin-right: 4px;
`
