import "./index.css"

import React from "react"
import ReactDOM from "react-dom"
import App from "./components/app"
import { getBrowserVisibilityProp, onVisibilityChange } from "./utils/tabHelpers"

// Render
ReactDOM.render(<App />, document.getElementById("root"))

// Detect tab changes and refresh page on expiry
document.addEventListener(getBrowserVisibilityProp(), onVisibilityChange, false)
